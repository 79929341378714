
import { computed, onMounted, reactive, ref, toRefs, watch } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { dateFormat, numberFormat } from '@/utils/helper/format';

const breadcrumbs = ['Store Report', ['Store List']];
const tableHeader = [
  {
    key: 'no',
    name: 'No.',
    sortable: true,
  },
  {
    name: 'Store',
    key: 'storeDetail',
    sortable: true,
  },
  {
    name: 'Full Price',
    key: 'sumFullPrice',
    sortable: false,
  },
  {
    name: 'Net Price',
    key: 'sumCommissionStore',
    sortable: false,
  },
  {
    name: 'Actions Detail',
    key: 'moreDetail',
    sortable: false,
  },
];

export default {
  name: 'Commission List',
  components: {
    Datatable,
  },
  setup() {
    const tableRef = ref<any>(null);
    const date = ref([]);
    const tableState = reactive({
      tableData: [],
      loading: false,
      total: 0,
      rowsPerPage: 10,
      currentPage: 1,
    });

    watch(
      () => [
        tableRef.value?.pagination.rowsPerPage,
        tableRef.value?.pagination.page,
      ],
      async ([rows, page]) => {
        tableState.currentPage = page;
        tableState.rowsPerPage = rows;

        if (tableState.rowsPerPage + 1 > tableState.total) {
          tableState.currentPage = 1;
        }

        await fetch(tableState.currentPage, tableState.rowsPerPage);
      }
    );

    watch(
      () => date.value,
      async (dateValue) => {
        if (dateValue.length == 2) {
          await fetch();
        }
      }
    );

    const fetch = async (page = 1, rowsPerPage = 10) => {
      try {
        const queryCondition: any = {
          page,
          pageSize: rowsPerPage,
        };

        if (date.value.length == 2) {
          queryCondition['start'] = new Date(
            dateFormat(date.value[0])
          ).getTime();
          queryCondition['end'] = new Date(dateFormat(date.value[1])).getTime();
        }

        const { data } = await ApiService.get(
          `/report/store-report?${new URLSearchParams(queryCondition)}`
        );
        if (data.status) {
          tableState.total = data.total;
          tableState.tableData = data.datas;
        }
        tableState.loading = false;
      } catch (error) {
        tableState.loading = false;
      }
    };

    const storeReportQuery = computed(() => {
      if (date.value.length == 2) {
        return `?${new URLSearchParams({
          startdate: `${new Date(dateFormat(date.value[0])).getTime()}`,
          enddate: `${new Date(dateFormat(date.value[1])).getTime()}`,
        })}`;
      } else {
        return '';
      }
    });

    onMounted(async () => {
      setCurrentPageBreadcrumbs(
        breadcrumbs[0] as string,
        breadcrumbs[1] as Array<Record<string, unknown> | string>
      );
      await fetch();
    });

    return {
      ...toRefs(tableState),
      date,
      tableHeader,
      breadcrumbs,
      tableRef,
      dateFormat,
      numberFormat,
      storeReportQuery,
    };
  },
};
